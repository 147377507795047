import { IProject } from "../shared/interfaces";
import { generateStringId } from "../shared/utils/utils";

export const projects: ReadonlyArray<IProject> = [
  {
    id: generateStringId(),
    name: "ACROSS™",
    createdAt: "i4energy",
    year: 2024,
    description:
      `A purpose-built digital energy ecosystem, specifically developed to offer Producers, 
       Consumers and Aggregators a tool to manage complex energy portfolios.`.trim(),
    skills: [
      "React",
      "Backbone.js",
      "TypeScript",
      "JavaScript",
      "Redux",
      "Redux Toolkit",
      "Node.js",
      "Deno",
      "Docker",
      "MySQL",
      "MongoDB",
      "ElasticSearch",
      "MQTT",
      "Kafka",
      "Node-RED",
      "Keycloak",
      "JSS",
      "HTML5",
      "CSS3",
      "SASS",
      "LESS",
    ],
    link: "https://i4.energy/across/",
    imageSrc: "/projects/across.webp",
    featured: true,
  },
  {
    id: generateStringId(),
    name: "Martin Beck Andersen",
    year: 2023,
    description: `My personal website built with Next.js.`.trim(),
    skills: ["Next.js", "React", "TypeScript", "HTML5", "CSS3", "Tailwind"],
    link: "https://martin-beck-andersen.com/",
    imageSrc: "/projects/mba-cv.webp",
  },
  {
    id: generateStringId(),
    name: "KetoFitGuru",
    year: 2023,
    description:
      `An affiliate marketing blog post site revolving around the ketogenic diet. 
       It was an experiment to utilize AI to build and maintain a project.`.trim(),
    skills: ["Next.js", "React", "TypeScript", "HTML5", "CSS3", "Tailwind"],
    link: "https://ketofitguru.vercel.app/",
    imageSrc: "/projects/keto-fit-guru.webp",
  },
  {
    id: generateStringId(),
    name: "Alere",
    year: 2022,
    description:
      `A commercial website developed with WordPress for a commercial representative and distributor of PROVIMI B.V. products.`.trim(),
    skills: ["WordPress", "PHP", "JavaScript", "HTML5", "CSS3"],
    link: "https://alere.gr/",
    imageSrc: "/projects/alere.webp",
  },
  {
    id: generateStringId(),
    name: "Marathos",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A commercial website developed with WordPress for a company offering meal plans and recipes delivered directly to your door.`.trim(),
    skills: [
      "WordPress",
      "Woocommerce",
      "PHP",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://marathos.gr",
    imageSrc: "/projects/marathos.webp",
  },
  {
    id: generateStringId(),
    name: "Nordia Marble",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A commercial website developed with WordPress for a company dealing with wholesale trading, 
       cutting and processing of marble, granite and artificial materials.`.trim(),
    skills: [
      "WordPress",
      "Woocommerce",
      "PHP",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://nordiamarble.com/",
    imageSrc: "/projects/nordia-marbles.webp",
  },
  {
    id: generateStringId(),
    name: "Podia Footcare",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A commercial website developed with WordPress for a company dealing with footcare.`.trim(),
    skills: [
      "WordPress",
      "PHP",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://podiafootcare.com/en/",
    imageSrc: "/projects/podia-footcare.webp",
    featured: true,
  },
  {
    id: generateStringId(),
    name: "Petropoulos Shell",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A commercial website developed with WordPress for a company, which is the official macro-distributor of 
       Shell lubricants and the official representative of Rocol lubricants.`.trim(),
    skills: [
      "WordPress",
      "PHP",
      "JavaScript",
      "jQuery",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://shell.petropoulos.com/",
    imageSrc: "/projects/petropoulos-shell.webp",
  },
  {
    id: generateStringId(),
    name: "LH Logismiki",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A commercial website developed with WordPress for a company, developing high quality software products 
       used for structural analysis and architectural design.`.trim(),
    skills: [
      "WordPress",
      "PHP",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://lhlogismiki.gr/en/",
    imageSrc: "/projects/lh-logismiki.webp",
  },
  {
    id: generateStringId(),
    name: "e-professor",
    createdAt: "SLEED",
    year: 2021,
    description: `An online e-learning platform developed in WordPress.`.trim(),
    skills: [
      "WordPress",
      "PHP",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://e-professor.gr/",
    imageSrc: "/projects/e-professor.webp",
    featured: true,
  },
  {
    id: generateStringId(),
    name: "Convert Group",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A commercial website developed with WordPress for a company dealing with data analytics market intelligence.`.trim(),
    skills: [
      "WordPress",
      "PHP",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://convertgroup.com/",
    imageSrc: "/projects/convert-group.webp",
    featured: true,
  },
  {
    id: generateStringId(),
    name: "Spitishop",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A webshop developed with Prestashop for a store selling anything regarding the home.`.trim(),
    skills: [
      "Prestashop",
      "PHP",
      "Symfony",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://spitishop.gr/",
    imageSrc: "/projects/spitishop.webp",
  },
  {
    id: generateStringId(),
    name: "Thanopoulos",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A webshop developed with Prestashop for a popular supermarket/grocery store in Greece.`.trim(),
    skills: [
      "Prestashop",
      "PHP",
      "Symfony",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://thanopoulos.gr/el/",
    imageSrc: "/projects/thanopoulos.webp",
  },
  {
    id: generateStringId(),
    name: "Alouette",
    createdAt: "SLEED",
    year: 2021,
    description:
      `A webshop developed with Prestashop for a company specializing in kids fashion.`.trim(),
    skills: [
      "Prestashop",
      "PHP",
      "Symfony",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://alouette.gr/en/",
    imageSrc: "/projects/alouette.webp",
  },
  {
    id: generateStringId(),
    name: "Dimitriadis Optics",
    createdAt: "SLEED",
    year: 2020,
    description:
      `A webshop developed with Prestashop for an optical store.`.trim(),
    skills: [
      "Prestashop",
      "PHP",
      "Symfony",
      "JavaScript",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://dimitriadisoptics.gr/el/",
    imageSrc: "/projects/dimitriadis-optics.webp",
    featured: true,
  },
  {
    id: generateStringId(),
    name: "PITEQ",
    createdAt: "ST-ConsultT ApS",
    year: 2018,
    description:
      `A portal for consultants and businesses, matching one with the other. 
       It also includes candidate management, financial system.`.trim(),
    skills: [
      "WordPress",
      "PHP",
      "JavaScript",
      "jQuery",
      "AJAX",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    imageSrc: "/projects/sis.webp",
  },
  {
    id: generateStringId(),
    name: "Sønderkobbel Strand Camping",
    year: 2016,
    description:
      `A website developed with WordPress for a holiday campsite.`.trim(),
    skills: ["WordPress", "PHP", "JavaScript", "HTML5", "CSS3", "Bootstrap"],
  },
  {
    id: generateStringId(),
    name: "Vinfloden",
    year: 2016,
    description:
      `A webshop developed with WordPress for a small wine distributor.`.trim(),
    skills: ["WordPress", "PHP", "JavaScript", "HTML5", "CSS3", "Bootstrap"],
  },
  {
    id: generateStringId(),
    name: "24-7Levering",
    year: 2016,
    description: `A commercial site for a delivery service.`.trim(),
    skills: ["WordPress", "PHP", "JavaScript", "HTML5", "CSS3", "Bootstrap"],
  },
  {
    id: generateStringId(),
    name: "Sønderborg Vækstråd",
    createdAt: "Haug-IT ApS",
    year: 2016,
    description:
      `A commercial site developed with WordPress for the entrepreneurial growth council of Sønderborg.`.trim(),
    skills: ["WordPress", "PHP", "JavaScript", "HTML5", "CSS3", "Bootstrap"],
    link: "https://svr.sonderborg.dk/",
    imageSrc: "/projects/sis.webp",
  },
];
