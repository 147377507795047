
import React from "react";
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";
import { IPageWrapperProps } from "@/app/shared/interfaces";

const PageWrapper: React.FC<IPageWrapperProps> = ({ children }) => {
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (window.innerWidth <= 768) return;

    const { currentTarget, clientX, clientY } = event;
    if (currentTarget instanceof HTMLElement) {
      const { left, top } = currentTarget.getBoundingClientRect();

      mouseX.set(clientX - left);
      mouseY.set(clientY - top);
    }
  };

  return (
    <div id="__next">
      <div className="group/spotlight relative" onMouseMove={handleMouseMove}>
        <motion.div
          className="pointer-events-none fixed inset-0 z-30 transition duration-300 lg:absolute"
          style={{
            background: useMotionTemplate`
            radial-gradient(
              600px at ${mouseX}px ${mouseY}px,
              rgba(29, 78, 216, 0.15),
              transparent 80%
            )
          `,
          }}
        />
        {children}
      </div>
    </div>
  );
};

export default PageWrapper;
