import React from "react";
import ParticleComponent from "../../../components/Particles/Particles";

const TopSection: React.FC = () => {
  return (
    <header id="top" className="relative overflow-hidden z-0 avoid-cls">
      <div className="relative overflow-hidden avoid-cls">
        <ParticleComponent />
      </div>
    </header>
  );
};

export default TopSection;
