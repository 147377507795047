import { IFeaturedPosts, IProject } from "../interfaces";

export const getHomepageURL = (): string => {
  if (typeof window !== "undefined") {
    const { protocol, host } = (window as Window).location;
    return `${protocol}//${host}/`;
  }

  return "https://martin-beck-andersen.com";
};

export const generateStringId = (arrayLength?: number): string => {
  if (arrayLength) {
    let result = "";
    Array.from(Array(arrayLength).keys()).forEach(() => {
      result += Math.random().toString(36).substring(7);
    });

    return result;
  }

  return Math.random().toString(36).substring(7);
};

export const getDomainFromURL = (url: string): string => {
  const parsedURL = new URL(url);
  return parsedURL.hostname;
};

export const getCurrentDate = (): string => {
  let todaysDate = new Date();

  const offset = todaysDate.getTimezoneOffset();

  todaysDate = new Date(todaysDate.getTime() - offset * 60 * 1000);

  return todaysDate.toISOString().split("T")[0];
};

export const getFormattedDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return new Date(date).toLocaleDateString(undefined, options);
};

export const sortPostsByDate = (
  posts: ReadonlyArray<IFeaturedPosts>
): IFeaturedPosts[] => {
  const allPosts = [...posts];

  return allPosts.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();

    return dateB - dateA;
  });
};

export const sortProjectsByDate = (
  projects: ReadonlyArray<IProject>
): IProject[] => {
  const allProjects = [...projects];
  return allProjects.sort((a, b) => b.year - a.year);
};

export const getSitemapCompatibleDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month starts from 0
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
